import React, { useRef, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
//504x672

import { generateToken, verifyToken, getPayload } from '@/utils';

import Page from '@molecules/Page';

import { FullVideo, CallibrationMark } from '@/components/StyledComponents';
import { resetSelection } from '@/data/AssetSelection';

let animationTimeout;
const NewestNFTPage = () => {
  const videoRef = useRef(null);
  const [stateCalibration, setStateCalibration] = useState(false);
  const [stateNFT, setStateNFT] = useState(null);

  const [socket, setSocket] = useState(null);

  const requestFS = () => {
    if (typeof window !== 'undefined') {
      if (!document.fullscreenElement) {
        window.document.documentElement.webkitRequestFullScreen();
      }
    }
  };
  useEffect(() => {
    setStateNFT(resetSelection().options.base.values[0]);
    if (typeof window !== 'undefined') {
      window.document.documentElement.addEventListener('fullscreenerror', event => {});
      window.addEventListener('keydown', event => {
        if (event.key === 'f') {
          requestFS();
        }
      });
      window.addEventListener('click', event => {
        requestFS();
      });
    }
  }, []);
  useEffect(() => {
    const socketUrl = process.env.GATSBY_DISPLAY_SERVER;
    const newSocket = io(socketUrl);
    newSocket.on('connect', () => {
      console.log('connected to socket');
    });
    newSocket.on('calibrationMode', enable => {
      setStateCalibration(enable);
    });
    newSocket.on('nft', data => {
      if (getPayload(data)?.payload?.STATE_SET_NFT) {
        // const newNFT = getPayload(data)?.payload?.STATE_SET_NFT;
        const newAsset = getPayload(data)?.payload?.STATE_SET_NFT.replace('nfts/', 'nfts/lg/');
        if (newAsset !== stateNFT) {
          if (videoRef.current) {
            videoRef.current.style.animation = 'none';
            videoRef.current.offsetHeight;
            videoRef.current.classList.add('fade-out');
          }
          clearTimeout(animationTimeout);
          animationTimeout = setTimeout(() => {
            setStateNFT(newAsset);
          }, 100);
        }
      }
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  return (
    <Page>
      {stateCalibration && <CallibrationMark />}
      {stateNFT && stateCalibration === false && (
        <FullVideo
          className="fullVideo"
          onLoadedData={() => {
            videoRef.current.style.animation = null;
            videoRef.current.classList.remove('fade-out');
          }}
          ref={videoRef}
          src={`${stateNFT}`}
          muted={true}
          playsInline={true}
          loop={true}
          autoPlay={true}
        />
      )}
    </Page>
  );
};

export default NewestNFTPage;
